import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '@components/layout';
import SEO from '@components/seo';
import DefaultImage from '@components/DefaultImage';
import SelectCategory from '@components/SelectCategory';

class BlogsPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      NoOfPost: 6,
      location: { state: { activeCategory: 'blog' } },
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    var lastScrollY = window.pageYOffset + 1100;

    if (lastScrollY > window.outerHeight) {
      var count = this.state.NoOfPost + 3;
      this.setState({
        NoOfPost: count,
      });
    }
  };

  render() {
    const { data } = this.props;
    const { NoOfPost } = this.state;

    return (
      <React.Fragment>
        <ul className="blog-list" onScroll={this.onScrollEvent}>
          <SelectCategory
            categories={data.data.allContentfulCategories}
            location={this.state.location}
          />
          {data.data.allContentfulBlogs.edges.slice(0, NoOfPost).map(items => (
            <li key={items.node.slug}>
              <div className="post-item template-square columned blog-card">
                <div className="post-thumbnail">
                  {items && items.node.featureImage ? (
                    <Img sizes={items.node.featureImage.fluid} />
                  ) : (
                    <DefaultImage />
                  )}
                </div>
                <div className="post-details">
                  <h2 className="post-title">
                    <Link to={`/blog/${items.node.slug}`}>
                      {items.node.title}
                    </Link>
                  </h2>
                  <div className="post-date">{items.node.createdAt}</div>
                  <div className="author">
                    {items && items.node.author.photo ? (
                      <Img sizes={items.node.author.photo.fluid} />
                    ) : (
                      <DefaultImage />
                    )}
                    <span className="name">{items.node.author.name}</span>
                  </div>
                  <p>{items.node.description.childMarkdownRemark.excerpt}</p>
                  <div className="read-more-positioning">
                    <Link
                      className="primButton blog"
                      to={`/blog/${items.node.slug}`}
                    >
                      Read more
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </React.Fragment>
    );
  }
}

const Blogs = data => (
  <Layout>
    <SEO title="Blogs" keywords={[`gatsby`, `application`, `react`]} />
    <div className="container blog-page">
      <BlogsPost data={data} />
    </div>
    <div className="contactus">
      <h2>Don't be a stranger!</h2>
      <div className="mailto-button">
        <Link className="primButton blog" to={'/contact'}>
          Get in touch!
        </Link>
      </div>
    </div>
  </Layout>
);

export default Blogs;

export const query = graphql`
  query BlogsQuery {
    allContentfulCategories {
      edges {
        node {
          name
          slug
          blogs {
            id
          }
        }
      }
    }
    allContentfulBlogs {
      edges {
        node {
          id
          title
          slug
          createdAt(formatString: "D MMMM, YYYY")
          author {
            name
            photo {
              fluid(maxWidth: 350) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
          description {
            childMarkdownRemark {
              excerpt(pruneLength: 250)
            }
          }
          featureImage {
            fluid(maxWidth: 1120) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  }
`;
